import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { BannerForm } from '@src/cms-components/hero-banner/components/banner-form/banner-form';
import { useCallback, useEffect, useState } from 'react';

const FormBannerPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'form_banner',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData && <BannerForm {...livePreviewData} />;
};

export default FormBannerPreviewPage;
